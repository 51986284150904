<template>
  <div class="fullheight">
    <div class="content-header flex-row flex-row-between">
      <h1>{{tituloComponente}}</h1>

      <div class="content-header-buttons flex-row">
        <div class="content-header-filter flex-row">
          <i class="icon-arrow-left"></i>
          <span @click="volverAtras">Volver atrás</span>
        </div>
        <button v-if="!tipoUsuarioSelected" class="btn-primary animated" @click="agregarTipoUsuario">Guardar</button>
        <button v-if="tipoUsuarioSelected && !view" class="btn-primary animated" @click="editar">Guardar</button>
        <button v-if="view" class="btn-primary animated" @click="cambiarAeditar">Editar</button>
      </div>
    </div>

    <div class="content-content flex-column flex-column-left flex-column-top">
      <form class="form-container fullwidth">

        <div class="flex-row flex-left flex-row-between">
          <div class="flex-column flex-top flex-eqw form-field-indicador-container">
            <label class="form-field-indicador" for="tipo-usuario-selected-nombre"
                   v-show="tipoUsuario.nombre !== ''">Nombre:</label>
            <input v-model="tipoUsuario.nombre" v-validate="'required'" id="tipo-usuario-selected-nombre" name="Nombre" :disabled='view'
                   class="form-field form-field-nomargin" :class="{error: errors.has('Nombre'), 'field-complete': tipoUsuario.nombre !== ''}"
                   type="text" placeholder="Nombre" maxlength="30">
          </div>

        </div>

<!--        MUESTRO SOLO ESTE BLOQUE SI EL USUARIO LOGUEADO ADMINISTRADOR, Y SI AL TIPO DE USUARIO QUE SE QUIERE EDITAR NO ES UN ADMINISTRADOR-->
        <div class="flex-column flex-left flex-row-between" v-if="usuarioLogueado.tipoUsuario.id === 1 && tipoUsuario.id !== 1">
          <div class="flex-row flex-top flex-eqw form-field-indicador-container">
            <switchButton v-model="tipoUsuario.carga_presupuesto" :disable="view" @toggle="toggleCargaPresupuesto">Carga presupuestos</switchButton>
          </div>
          <div class="flex-row flex-top flex-eqw form-field-indicador-container">
            <switchButton v-model="tipoUsuario.aprueba_presupuesto" :disable="view" @toggle="toggleApruebaPresupuesto">Aprueba presupuestos</switchButton>
          </div>

        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import ajax from '@/ajax'
import ConstantsHelper from '@/ConstantsHelper'
import switchButton from '../switchButton'

export default {
  name: 'crearOEditar',
  props: ['view', 'edit', 'agregar', 'tipoUsuarioSelected'],
  components: {
    switchButton
  },
  data () {
    return {
      usuarioLogueado: {
        id: '',
        tipoUsuario: {
          id: ''
        }
      },
      tipoUsuario: {
        id: '',
        nombre: '',
        carga_presupuesto: false,
        aprueba_presupuesto: false,
        mostrarMenu: false
      }
    }
  },
  methods: {
    volverAtras () {
      EventBus.$emit('toggleShow', true)
      this.initTipoUsuario()
      this.$emit('mostrarListado')
    },
    initTipoUsuario () {
      this.tipoUsuario = {
        id: '',
        nombre: '',
        carga_presupuesto: false,
        aprueba_presupuesto: false,
        mostrarMenu: false
      }
    },
    agregarTipoUsuario () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('POST', ConstantsHelper.getURLs().v1 + 'usuarios-tipos.php', { tipoUsuario: this.tipoUsuario, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.tipoUsuario.id = response.id_inserted
                this.tipoUsuario.state = 1
                EventBus.$emit('tipoUsuarioAgregado', this.tipoUsuario)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initTipoUsuario()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })
              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    editar () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('loading', true)

          ajax('PUT', ConstantsHelper.getURLs().v1 + 'usuarios-tipos.php', { tipoUsuario: this.tipoUsuario, usuarioLogueado: JSON.parse(localStorage.getItem('usuarioGalponLogueado')) })
            .then((response) => {
              let type = 'error'
              if (response.status === 1) {
                this.tipoUsuario.mostrarMenu = false
                EventBus.$emit('tipoUsuarioEditado', this.tipoUsuario)
                this.$emit('mostrarListado')
                type = 'correct'
                this.initTipoUsuario()
              }

              EventBus.$emit('generateToast', {
                message: response.message,
                type: type
              })

              this.$emit('loading', false)
            })
            .catch(() => {
              this.$emit('loading', false)
            })
        }
      })
    },
    cambiarAeditar () {
      this.$emit('editar', this.tipoUsuario)
    },

    toggleHeader () {
      if (this.agregar || this.view || this.edit) {
        EventBus.$emit('toggleShow', false)
      } else {
        EventBus.$emit('toggleShow', true)
      }
    },
    toggleCargaPresupuesto (newValue) {
      this.tipoUsuario.carga_presupuesto = newValue
    },
    toggleApruebaPresupuesto (newValue) {
      this.tipoUsuario.aprueba_presupuesto = newValue
    }
  },
  computed: {
    tituloComponente () {
      if (this.view) {
        return 'Visualizar tipo de usuario'
      } else {
        return (this.tipoUsuarioSelected) ? 'Editar tipo de usuario' : 'Agregar tipo de usuario'
      }
    }
  },
  mounted () {
    this.usuarioLogueado = JSON.parse(localStorage.getItem('usuarioGalponLogueado'))
  },
  watch: {
    tipoUsuarioSelected: {
      handler () {
        if (this.tipoUsuarioSelected) {
          this.tipoUsuario = this.tipoUsuarioSelected
        } else {
          this.initTipoUsuario()
        }
      }
    },
    agregar () {
      this.toggleHeader()
    },
    view () {
      this.toggleHeader()
    },
    edit () {
      this.toggleHeader()
    }
  }
}
</script>
