<template>
  <div class="switch-button-control">
    <div class="switch-button" :class="{ enabled: isEnabled, disable: disable }" @click="toggle" :style="{'--color': color}">
      <div class="button"></div>
    </div>
    <div class="switch-button-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'switchButton',
  model: {
    prop: 'isEnabled',
    event: 'toggle'
  },
  props: {
    isEnabled: Boolean,
    disable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: '#5E52F6'
    }
  },
  methods: {
    toggle: function () {
      if (!this.disable) {
        this.$emit('toggle', !this.isEnabled)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  // For switch-button styling
  .switch-button-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2vw);

    .switch-button {
      $switch-button-height: 1.2em;
      $switch-button-color: var(--color);
      $switch-button-color-disable: #8A97AB;
      $switch-button-border-thickness: 2px;
      $switch-transition: all 0.3s ease-in-out;
      $switch-is-rounded: true;

      height: $switch-button-height;
      width: calc(#{$switch-button-height} * 2);
      border: $switch-button-border-thickness solid $switch-button-color-disable;
      /*box-shadow: inset 0px 0px $switch-button-border-thickness 0px rgba(0, 0, 0, 0.33);*/
      border-radius: if($switch-is-rounded, $switch-button-height, 0);
      background-color: $switch-button-color-disable;

      transition: $switch-transition;

      $button-side-length: calc(
        #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
      );

      cursor: pointer;
      order: 1;

      .button {
        height: $button-side-length;
        width: $button-side-length;
        border: $switch-button-border-thickness solid #FFFFFF;
        border-radius: if($switch-is-rounded, $button-side-length, 0);
        /*background: $switch-button-color;*/
        background: #FFFFFF;

        transition: $switch-transition;
      }

      &.disable {
        cursor: no-drop;
      }

      &.enabled {
        background-color: $switch-button-color;
        border: $switch-button-border-thickness solid $switch-button-color;
        box-shadow: none;

        .button {
          background: #FFFFFF;
          transform: translateX(
              calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
          );
        }
      }
    }

    .switch-button-label {
      margin-left: 10px;
      order: 0;
    }
  }
</style>
